<div class="navbar-container">
  <div class="above-nav d-flex align-items-center">
    <div class="container dropdown d-flex flex-row-reverse pr-0">
      <!--<a class="dropdown-toggle text-white text-decoration-none" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Lingua
      </a>
      <div #dropdown class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
        <a #italiano class="dropdown-item active" routerLink="home" (click)="this.mainService.swapActive(dropdown,italiano)">
          Italiano
        </a>
        <a #inglese class="dropdown-item" routerLink="home" (click)="this.mainService.swapActive(dropdown,inglese)">
          English
        </a>
      </div>-->
    </div>
  </div>
  <div class="navbar-spec">
    <div class="container-lg p-0" role="navigation">
      <app-navbar></app-navbar>
    </div>
  </div>
</div>

<div class="container-lg p-0">
  <router-outlet></router-outlet>
</div>

<footer class="page-footer bottom-foot">
  <div class="pt-4 pb-4 d-flex justify-content-between container-lg">
    <div class="d-flex flex-column">
      <p class="h2">SI.BA</p>
      <span>P.IVA: 04729270282</span>
    </div>
    <div class="d-flex flex-column">
      <p class="h6">Via Castellana 128</p>
      <p class="h6"> San Martino di Lupari</p>
      <p class="h6"> Padova</p>
    </div>
  </div>
</footer>
