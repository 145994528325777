import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MainService } from '../services/main.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private router: Router, public mainService: MainService) { }
  ngOnInit() {
    this.router.events.pipe( filter((event: any) => event instanceof NavigationEnd) )
      .subscribe(event => {
        if (event.url !== '/home' || event.url !== '') {
          const ul = document.getElementsByClassName('navbar-nav')[0];
          const li = ul.getElementsByClassName(event.url.substring(1))[0];
          this.mainService.changeActiveItem(ul, li);
        }
      });
  }
}
