import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  changeActiveItem(ul, li) {
    const span = ul.getElementsByClassName('sr-only')[0];
    span.remove();
    this.swapActive(ul, li);
    li.getElementsByTagName('a')[0].appendChild(span);
  }
  swapActive(ul, li) {
    ul.getElementsByClassName('active')[0].classList.remove('active');
    li.classList.add('active');
  }
  fullSize(img) {
    img.classList.add('overlay');
  }
}

