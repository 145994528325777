import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AziendaComponent } from './azienda/azienda.component';
import { ContattiComponent } from './contatti/contatti.component';
import { HomeComponent} from './home/home.component';
import { NavbarComponent } from './navbar/navbar.component';
import { CarouselComponent } from './carousel/carousel.component';
import { MainService } from './services/main.service';
import { MapsComponent } from './maps/maps.component';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    AziendaComponent,
    CarouselComponent,
    ContattiComponent,
    HomeComponent,
    NavbarComponent,
    MapsComponent,
    ImageDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    MatDialogModule,
    BrowserAnimationsModule
  ],
  providers: [
    MainService
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
