import {Component} from '@angular/core';
import {MainService} from '../services/main.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent {public mainService: MainService}

