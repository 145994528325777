import { Component} from '@angular/core';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css']
})

export class MapsComponent {
  public link = 'https://www.google.it/maps/place/Si.Ba+Srl/@45.6662839,11.8734654,17z/data=!3m1!4b1!4m5!3m4!1s0x477929f805e07267:0xa540759fa5341f5e!8m2!3d45.6662802!4d11.8756541';
}
