<div [ngSwitch]="this.data">
  <div *ngSwitchCase="'cappotto.jpeg'">
    <img src="assets/resources/{{this.data}}" alt="{{this.data}}" class="img-fluid">
    <p>Applicazione cappotto</p>
  </div>
  <div *ngSwitchCase="'pittura-prima.png'">
    <img src="assets/resources/{{this.data}}" alt="{{this.data}}" class="img-fluid">
    <p>Pittura interna - prima</p>
  </div>
  <div *ngSwitchCase="'pittura-dopo.jpeg'">
    <img src="assets/resources/{{this.data}}" alt="{{this.data}}" class="img-fluid">
    <p>Pittura interna - dopo</p>
  </div>
  <div *ngSwitchCase="'pittura.jpeg'">
    <img src="assets/resources/{{this.data}}" alt="{{this.data}}" class="img-fluid">
    <p>Pittura esterna</p>
  </div>
  <div *ngSwitchCase="'ripristino.jpg'">
    <img src="assets/resources/{{this.data}}" alt="{{this.data}}" class="img-fluid">
    <p>Ripristino cemento armato e pittura esterna</p>
  </div>
  <div *ngSwitchCase="'resina.png'">
    <img src="assets/resources/{{this.data}}" alt="{{this.data}}" class="img-fluid">
    <p>Applicazione resina</p>
  </div>
  <div *ngSwitchCase="'sigillatura.jpeg'">
    <img src="assets/resources/{{this.data}}" alt="{{this.data}}" class="img-fluid">
    <p>Applicazione sigillatura</p>
  </div>
  <div *ngSwitchCase="'cartongesso.jpeg'">
    <img src="assets/resources/{{this.data}}" alt="{{this.data}}" class="img-fluid">
    <p>Cartongesso interno</p>
  </div>
</div>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Chiudi</button>
</mat-dialog-actions>
