<div class="pt-5 pb-5 factory-info">
  <ul class="list-unstyled">
    <li class="h2">Sigillature, stuccature e restauri conservativi
      <p class="pt-2 section-description">
        La ditta SI.BA. esegue ogni tipo di sigillatura idonea alla tenuta dell'acqua,
        al fuoco e al fumo su edifici civili di nuova e vecchia costruzione,
        edifici industriali, all'interno di canali di cemento, canali Enel e vasche di depurazione
        acque. Le sigillature si concretizzano con fornitura e posa in opera di sigillanti
        siliconici, acrilici, poliuretanici, acripoliuretanici e polosulfurici a base di thiokol,
        in corrispondenza dei giunti di esercizio e/o strutturali dei fabbricati e dei manufatti.
      </p>
      <div id="sibaCarousel1" class="carousel slide carousel-size mt-4" data-interval=0 data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#sibaCarousel1" data-slide-to="0" class="active"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="assets/resources/sigillatura.jpeg" class="d-block w-100" alt="sigillatura">
            <div class="carousel-caption d-none d-md-block">
              <h4 class="rgba-back">Applicazione sigillatura</h4>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#sibaCarousel1" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#sibaCarousel1" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <div class="mobileGallery d-flex flex-row flex-wrap">
        <button mat-button (click)="openDialog('sigillatura.jpeg')">
          <img src="assets/resources/sigillatura.jpeg" alt="Thumbnail">
        </button>
      </div>
    </li>
    <li class="h2 pt-3">Rivestimenti a cappotto
      <p class="pt-2 section-description">
        Lavorazione di primaria importanza per SI.BA. è rappresentata dalla termocoibentazione a
        cappotto di pareti, fabbricati o solo porzioni di fabbricati. Da sempre sensibile alle nuove
        tendenze sia in fatto estetico che prestazionale, il cappotto è diventato essenziale per
        risolvere problemi di diversa natura come la coibentazione termica, crepe, muffe e molto altro.
        Per questo motivo, oltre al fattore legato alla qualità dell'intervento che contraddistingue SI.BA.
        da ormai 35 anni, i nostri operatori utilizzano i migliori materiali per avere un'ottima
        coibentazione caldo-freddo.
      </p>
      <div id="sibaCarousel2" class="carousel slide carousel-size mt-4" data-interval=0 data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#sibaCarousel2" data-slide-to="0" class="active"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="assets/resources/cappotto.jpeg" class="d-block w-100" alt="cappotto">
            <div class="carousel-caption d-none d-md-block">
              <h4 class="rgba-back">Applicazione cappotto</h4>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#sibaCarousel2" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#sibaCarousel2" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <div class="mobileGallery d-flex flex-row flex-wrap">
        <button mat-button (click)="openDialog('cappotto.jpeg')">
          <img src="assets/resources/cappotto.jpeg" alt="Thumbnail">
        </button>
      </div>
    </li>
    <li class="h2 pt-3">Pitture interne ed esterne
      <p class="pt-2 section-description">
        SI.BA. esegue operazioni di tinteggiatura interna ed esterna.
        Per la prima si applicano diversi prodotti, come tempere da interno, lavabili, antimuffa o
        antimacchia, in base alle esigenze del cliente o alla destinazione di utilizzo degli ambienti.
        Inoltre vengono applicati prodotti di decorazione degli ambienti come marmorini, grasselli e stucchi.
        Per la seconda vengono utilizzate diverse tecniche come idropitture acriliche lisce, con farina
        di quarzo, silossaniche e rivestimenti a spessore, ad esempio bucciati o graffiati. Inoltre SI.BA
        applica la tecnica dell'idropittura a silicati, indicata per interventi in ambienti particolari come i
        centri storici.
      </p>
      <div id="sibaCarousel3" class="carousel slide carousel-size mt-4" data-interval=0 data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#sibaCarousel3" data-slide-to="0" class="active"></li>
          <li data-target="#sibaCarousel3" data-slide-to="1"></li>
          <li data-target="#sibaCarousel3" data-slide-to="2"></li>
          <li data-target="#sibaCarousel3" data-slide-to="3"></li>
          <li data-target="#sibaCarousel3" data-slide-to="4"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="assets/resources/pittura-prima.png" class="d-block w-100" alt="">
            <div class="carousel-caption d-none d-md-block">
              <h4 class="rgba-back">Pittura interna - Prima</h4>
            </div>
          </div>
          <div class="carousel-item">
            <img src="assets/resources/pittura-dopo.jpeg" class="d-block w-100" alt="">
            <div class="carousel-caption d-none d-md-block">
              <h4 class="rgba-back">Pittura interna - Dopo</h4>
            </div>
          </div>
          <div class="carousel-item">
            <img src="assets/resources/pittura.jpeg" class="d-block w-100" alt="">
            <div class="carousel-caption d-none d-md-block">
              <h4 class="rgba-back">Pittura esterna</h4>
            </div>
          </div>
          <div class="carousel-item">
            <img src="assets/resources/ripristino.jpg" class="d-block w-100" alt="">
            <div class="carousel-caption d-none d-md-block">
              <h4 class="rgba-back">Ripristino cemento armato e pittura esterna</h4>
            </div>
          </div>
          <div class="carousel-item">
            <img src="assets/resources/resina.png" class="d-block w-100" alt="applicazione resina">
            <div class="carousel-caption d-none d-md-block">
              <h4 class="rgba-back">Applicazione resina</h4>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#sibaCarousel3" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#sibaCarousel3" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <div class="mobileGallery d-flex flex-row flex-wrap">
        <button mat-button (click)="openDialog('pittura-prima.png')" class="m-2">
          <img src="assets/resources/pittura-prima.png" alt="Thumbnail">
        </button>
        <button mat-button (click)="openDialog('pittura-dopo.jpeg')" class="m-2">
          <img src="assets/resources/pittura-dopo.jpeg" alt="Thumbnail">
        </button>
        <button mat-button (click)="openDialog('pittura.jpeg')" class="m-2">
          <img src="assets/resources/pittura.jpeg" alt="Thumbnail">
        </button>
        <button mat-button (click)="openDialog('ripristino.jpg')" class="m-2">
          <img src="assets/resources/ripristino.jpg" alt="Thumbnail">
        </button>
        <button mat-button (click)="openDialog('resina.png')" class="m-2">
          <img src="assets/resources/resina.png" alt="Thumbnail">
        </button>
      </div>
    </li>
    <li class="h2 pt-3">Cartongesso di ogni genere
    <p class="pt-2 section-description">
      SI.BA. eccelle nella realizzazione di opere in cartongesso di ogni forma e dimensione. Vengono utilizzati
      diversi tipi di cartongesso: antincendio, idrorepellente e lastra standard. Quest'ultima risulta adatta
      anche per la creazione di cappotti interni per un maggiore isolamento termico, rivestendo i muri perimetrali.
      In base agli ambienti e alle esigenze, il cartongesso può essere semplice, a doppia lastra o a doppia
      orditura metallica.
    </p>
    <div id="sibaCarousel4" class="carousel slide carousel-size mt-4" data-interval=0 data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#sibaCarousel4" data-slide-to="0" class="active"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="assets/resources/cartongesso.jpeg" class="d-block w-100" alt="cartongesso">
          <div class="carousel-caption d-none d-md-block">
            <h4 class="rgba-back">Cartongesso interno</h4>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#sibaCarousel4" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#sibaCarousel4" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <div class="mobileGallery d-flex flex-row flex-wrap">
      <button mat-button (click)="openDialog('cartongesso.jpeg')">
        <img src="assets/resources/cartongesso.jpeg" alt="Thumbnail">
      </button>
    </div>
    </li>
  </ul>
</div>

