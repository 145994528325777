<!--<app-carousel></app-carousel>-->
<div><img src="assets/resources/home.jpg" class="w-100 home" alt="home photo"></div>
<div class="description-container">
  <h1>SI.BA</h1>
  <p class="text-left text-break">
    La ditta SI.BA opera nel nord e centro Italia da più di 35 anni, specializzandosi  nel settore
    dell'edilizia civile e industriale, privata e statale, realizzando pitture e decorazioni,
    contropareti in cartongesso e controsoffitti, isolamenti a cappotto, ripristino cementi armati e
    sigillature.</p>
    <p class="text-left text-break">
    Possiamo contare su una decina di dipendenti e altrettanti collaboratori specializzati
    che operano con le tecnologie più avanzate. Il nostro parco automezzi fa parte delle attrezzature che
    ci consentono di effettuare lavori di in tempi rapidi e a regola d'arte. La ditta Si.Ba è inoltre
    dotata di ponteggi e altri mezzi di lavoro propri riducendo così i tempi e i costi di realizzazione,
    migliorando l'apporto logistico nei cantieri.</p>
    <p class="text-left text-break">
    Si.Ba accoglie positivamente anche il concetto  legato alle agevolazioni statali per privati e società.
    Per ogni esigenza siamo in grado di offrire tempestivamente un preventivo dettagliato, mantenendo sempre alta
    la qualità dei materiali e proponendo il miglio rapporto qualità-prezzo.
    Siamo a vostra completa disposizione per soddisfare ogni vostra esigenza, forti della nostra esperienza e
    della nostra passione.
    </p>
</div>
<app-maps></app-maps>
