import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MainService} from '../services/main.service';
import {ImageDialogComponent} from '../image-dialog/image-dialog.component';

@Component({
  selector: 'app-azienda',
  templateUrl: './azienda.component.html',
  styleUrls: ['./azienda.component.css']
})
export class AziendaComponent implements OnInit {

  constructor(public mainService: MainService, public dialog: MatDialog) { }

  ngOnInit() {}

  openDialog(img) {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '350px';
    dialogConfig.width = '600px';
    dialogConfig.data = img;
    const dialogRef = this.dialog.open(ImageDialogComponent, dialogConfig);
  }
}
