<div class="d-flex flex-column pt-5 info-container">
  <div>
    <p class="h2">Orari d'ufficio</p>
    <ul class="list-unstyled h5">
      <li>Lunedì: 08:00 - 18:00</li>
      <li>Martedì: 08:00 - 18:00</li>
      <li>Mercoledì: 08:00 - 18:00</li>
      <li>Giovedì: 08:00 - 18:00</li>
      <li>Venerdì: 08:00 - 18:00</li>
      <li>Sabato: 08:00 - 12:00</li>
      <li>Domenica: chiuso</li>
    </ul>
  </div>
  <div class="d-flex flex-column mt-5 mb-5">
    <div class="d-flex flex-row align-items-center">
      <img src="assets/resources/telephone.png" class="icon-size">
      <div lass="d-flex flex-column phone">
        <p class="h4 pl-3 pr-5 mb-0">Ufficio:</p>
        <p class="h4 pl-3 pr-5 mb-3"><a href="tel:+39 0499469170">049 9469170</a></p>
        <p class="h4 pl-3 pr-5 mb-0">Luigino:</p>
        <p class="h4 pl-3 pr-5 mb-3"><a href="tel:+39 3357883453">335 7883453</a></p>
        <p class="h4 pl-3 pr-5 mb-0">Alberto:</p>
        <p class="h4 pl-3 pr-5 mb-3"><a href="tel:+39 3452119272">345 2119272</a></p>
      </div>
    </div>
    <div class="d-flex flex-row align-items-center pt-5">
      <img src="assets/resources/mail.svg" class="icon-size">
      <p class="h4 pl-3 mail"><a class="pb-2" href="mailto:siba.applicazioni@gmail.com">siba.applicazioni@gmail.com</a></p>
    </div>
  </div>
</div>
