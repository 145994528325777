<nav class="navbar navbar-expand-lg navbar-light pr-0 pl-0">
  <a class="navbar-brand" routerLink="home">
    <img class="logo" src="../../assets/resources/favicon2.jpg" alt="Logo di Siba">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul #itemlist class="navbar-nav d-flex justify-content-end">
      <li #navitem1 class="nav-item active pr-3 border-right home" (click)="this.mainService.changeActiveItem(itemlist, navitem1)">
        <a class="nav-link" routerLink="home">HOME <span class="sr-only">(current)</span></a>
      </li>
      <li #navitem2 class="nav-item pr-3 border-right azienda" (click)="this.mainService.changeActiveItem(itemlist, navitem2)">
        <a class="nav-link" routerLink="azienda">L'AZIENDA</a>
      </li>
      <li #navitem3 class="nav-item contatti pr-0 mr-0" (click)="this.mainService.changeActiveItem(itemlist, navitem3)">
        <a class="nav-link" routerLink="contatti">CONTATTI</a>
      </li>
    </ul>
  </div>
</nav>
